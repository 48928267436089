import React from 'react'
import Navigation from "../components/navigation";
import { Link } from 'gatsby';
import "@fontsource/space-grotesk/600.css";
import "@fontsource/space-grotesk/400.css";
import '../styles/global.css';
import { Helmet } from 'react-helmet';
import CookieConsent from 'react-cookie-consent';

export default function Layout({ children }) {
    return (
        <div className="layout">
            <Helmet>
                <html lang="en"/>
            </Helmet>
            <Navigation/>
            <div className="content">
                { children }
            </div>
            <footer>
            <div className="w-4/5 m-auto py-14 flex flex-col lg:flex-row justify-around lg:items-end">
      <p><strong><Link to="/">heinrig filmtechnik</Link></strong><br/>
heinrich & gilstein gbr<br/>
albert-schweitzer-straße 12<br/>
64807 dieburg <br/>
germany
</p>
<div className="flex flex-col items-end">
<Link className="text-right" to="/jobs/">jobs</Link>
<Link className="text-right" to="/contact/">contact</Link>
<Link className="text-right" to="/legal/">legal disclosure</Link>
</div>

      </div>
            </footer>

        <CookieConsent
          enableDeclineButton
          location="bottom"
          buttonText="Accept"
          declineButtonText="Decline"
          style={{ background: "#FFF", color: "black"}}
          cookieName="gatsby-gdpr-google-tagmanager"
          buttonClasses="button m-5"
          containerClasses='fixed w-full flex flex-col lg:flex-row justify-between p-5 items-center'
          disableStyles={true}
          declineButtonClasses='button bg-gray-100 hover:bg-gray-200 m-5'
        >
          <span style={{ fontSize: "18px" }}>🍪🍪🍪 Cookies! 🍪🍪🍪</span><br />
          We use cookies to optimize our website. Please accept them to improve user experience.<br />
          {" "}More information about our <Link to={"/legal"} className='inlinelink'>privacy policy here.</Link>
        </CookieConsent>
        </div>
    )
}
