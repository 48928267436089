import React, {useState} from "react"
import { Link } from "gatsby"
import HeinrigLogo from "../images/heinrig_text_black.svg"


export default function Navigation() {
  const [sidebar, setSidebar] = useState(false)

  const showSidebar = () => setSidebar(!sidebar)

    return(
    <div className="nav">
      <nav className="h-10 fixed inset-x-0 bg-white z-40 flex flex-row items-center justify-between">
        <button className="h-full w-1/6 lg:w-10 mx-3 lg:mx-5" onClick={showSidebar}>
          <img src={'../../hamburger.svg'} alt="menu-icon" className="h-1/2 w-1/2" />
        </button>
        <Link className="w-4/6" to="/"><img src={HeinrigLogo} alt="logo" className="w-1/4 m-auto h-full lg:p-3 md:w-1/6 lg:w-1/12 xl:w-28 "/></Link>
        <div className="w-1/6 lg:w-10 mx-3 lg:mx-5 h-full"></div>
      </nav>
      <div className={sidebar ? 'nav-menu active' : 'nav-menu'}>
        <div className="h-screen flex flex-col items-baseline p-10 justify-between w-1/4">
          <div></div>
        <div className="flex flex-col items-baseline justify-center text-xl font-semibold py-32">
          <Link to="/">Home</Link>
          <Link to="/products/quicksticks">Quicksticks</Link>
          <Link to="/philosophy">Philosophy</Link>
          <a href="https://shop.heinrig.tech">Shop</a>
        </div>
        <div className="flex flex-col items-baseline justify-center py-10">
          <Link to="/jobs">Jobs</Link>
          <Link to="/contact">Contact</Link>
          <Link to="/legal">Legal</Link>
        </div>
        </div>
      </div>
    </div>
)}